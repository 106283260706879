import React, { useState } from "react";
import "../App.sass";
import "@fortawesome/react-fontawesome";

interface NavvarBurgerProps {
  onToggleMenu: React.MouseEventHandler<HTMLAnchorElement>;
  active: boolean;
}

const NavbarBurger = (props: NavvarBurgerProps) => (
  <a
    role="button"
    onClick={props.onToggleMenu}
    className={`navbar-burger burger ${props.active && "is-active"}`}
  >
    <span aria-hidden="true" />
    <span aria-hidden="true" />
    <span aria-hidden="true" />
  </a>
);

export const TopNavMenu = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeSearchBotton, setActiveSearchBotton] = useState(false);
  const [query, setQuery] = useState("");

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value == null ? "" : e.target.value);
    setActiveSearchBotton(e.target.value === "");
  };

  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://7gon.net/">
            Septagon
          </a>
          <NavbarBurger
            active={activeMenu}
            onToggleMenu={() => setActiveMenu((x) => !x)}
          />
        </div>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">雑談</a>
              <div className="navbar-dropdown">
                <a className="navbar-item" href="/news7vip/">
                  裏VIP
                </a>
                <a className="navbar-item" href="/coffeehouse/">
                  雑談ヘルノワール
                </a>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">趣味</a>
              <div className="navbar-dropdown">
                <a className="navbar-item" href="/hobbies/">
                  ホビー全般
                </a>
                <a className="navbar-item" href="/create/">
                  創作全般
                </a>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">ニュース</a>
              <div className="navbar-dropdown">
                <a className="navbar-item" href="/newsflash/">
                  ニュース超速報
                </a>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">運営</a>
              <div className="navbar-dropdown">
                <a className="navbar-item" href="/operateandsaku/">
                  運用情報・削除依頼
                </a>
                <a className="navbar-item" href="/labo/">
                  実験（開発）
                </a>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">避難所</a>
              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  href="http://septagon.s602.xrea.com/nose7vip/"
                >
                  裏VIP避難所
                </a>
                <a
                  className="navbar-item"
                  href="http://septagon.s602.xrea.com/createnose"
                >
                  創作全般避難所
                </a>
                <a
                  className="navbar-item"
                  href="http://septagon.s602.xrea.com/labonode/"
                >
                  実験板避難所
                </a>
              </div>
            </div>
          </div>
          <form className="navbar-end">
            <div className="navbar-item">
              <p>スレタイ検索</p>
            </div>
            <div className="navbar-item field">
              <p className="control">
                <input
                  className="input"
                  type="search"
                  placeholder="Search..."
                  onChange={onChangeInput}
                />
              </p>
              <div className="navbar-item">
                <button
                  className="button"
                  type="submit"
                  onClick={() => () =>
                    window.location.assign(
                      "https://7gon.net/kako?query=" + query
                    )}
                  disabled={activeSearchBotton}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          className={`navbar-menu ${activeMenu ? "is-active" : ""}`}
          style={{ display: activeMenu ? "flex" : "none" }}
        >
          <div className="navbar-start">
            <a className="navbar-item" href="/news7vip/">
              裏ニュー速VIP
            </a>
            <a className="navbar-item" href="/coffeehouse/">
              雑談へルノワール
            </a>
            <a className="navbar-item" href="/hobbies/">
              ホビー全般
            </a>
            <a className="navbar-item" href="/operateandsaku/">
              運用情報
            </a>
            <hr style={{ marginBottom: 3, marginTop: 3 }}></hr>
            <a
              className="navbar-item"
              href="http://septagon.s602.xrea.com/nose7vip/"
            >
              裏VIP避難所
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};
