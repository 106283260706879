import React from "react";

export const NotificationContent = () => (
  <div>
    <h2>お知らせ</h2>
    <ul>
      <li>
        <p>
          BB2Cに追加する際は下記の手順で追加してください
          ①BB2C下部の+ボタンをタップ ②「外部板の追加」をタップ
          ③"板名"には任意の名前を、"URL"には「http://」と末尾の「/」を抜いたURLを入力して完了ボタンを押す
          例）裏ニュー速VIP（http://7gon.net/news7vip/）の場合は「7gon.net/news7vip」と入力してください
        </p>
      </li>
      <li>
        <p>
          現在ニュース超速報板においてぽんなシステム関連のテストを行っております
          (詳細はニュース超速報板のローカルルール参照)
        </p>
      </li>
    </ul>
  </div>
);
