import React from "react";
import "../App.sass";

export const IndexTitles = () => (
  <section className="hero">
    <div className="hero-body">
      <div className="container">
        <h1 className="title is-1">セプタゴン</h1>
        <h2 className="subtitle  is-3">
          ～「楽園」から「地獄」までを手広くカバーする巨大掲示板群～
        </h2>
      </div>
    </div>
  </section>
);
