import React from "react";
import "../App.sass";
import { NotificationContent } from "../components/NotificationContent";
import { ResponseCountTable } from "./ResponseCountTable";
import { RecentlyNews } from "./RecentlyNews";
import { FutureTask } from "./FutureTask";

export const MainContent = () => (
  <div className="column is-three-quarters">
    <div className="container">
      <div className="content is-medium">
        <NotificationContent />
        {/* <div style={{ marginTop: 10 }}>
          <ResponseCountTable />
        </div> */}
        <div style={{ marginTop: 10 }}>
          <RecentlyNews />
        </div>

        <div style={{ marginTop: 10 }}>
          <FutureTask />
        </div>
      </div>
    </div>
  </div>
);
