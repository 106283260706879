import React from "react";
import "./App.sass";
import "@fortawesome/react-fontawesome";
import { PageBody } from "./components/PageBody";

const App: React.FC = () => {
  return (
    <PageBody/ >
  );
};

export default App;
