import React from "react";
import "../App.sass";
import { IndexTitles } from "./IndexTitles";
import { BoardsSlideShow } from "./BoardsSlideShow";
import { SideNavBar } from "./SideNavBar";
import { MainContent } from "./MainContent";
import { TopNavMenu } from "./TopNavMenu";
import { PageFooter } from "./PageFooter";

export const PageBody = () => (
  <div>
    <TopNavMenu />
    <IndexTitles />
    <BoardsSlideShow />
    <div className="container">
      <div className="columns">
        <MainContent />
        <SideNavBar />
      </div>
    </div>
    <div style={{ marginBottom: 10 }}></div>

    <PageFooter />
  </div>
);
