import React from "react";
import "../App.sass";
import { Timeline } from "react-twitter-widgets";

export const SideNavBar = () => (
  <div className="column">
    <Timeline
      dataSource={{
        sourceType: "profile",
        screenName: "7gon_official",
      }}
      options={{
        username: "7gon_Official",
        height: "100",
      }}
    />
  </div>
);
