import React from "react";
import Enumerable from "linq";

export class NewsItem {
  public date: string;
  public name: string;
  constructor(date: string, name: string) {
    this.date = date;
    this.name = name;
  }
  public static defaultNewsItems: NewsItem[] = [];
}

export class RecentlyNews extends React.Component {
  constructor(props: any) {
    super(props);
    this.clickShowMoreButton = this.clickShowMoreButton.bind(this);
  }
  state = {
    showNewsItems: NewsItem.defaultNewsItems,
    isShowingMoreItems: false,
  };
  news: NewsItem[] = [
    { date: "2019-08-13", name: "過去スレッド機能の改善を行いました。" },
    {
      date: "2018-05-05",
      name: "御陰様でセプタゴンは5周年を迎えました。今後ともよろしくお願いします",
    },
    {
      date: "2018-01-01",
      name: "あけましておめでとうございます。2018年もセプタゴンをよろしくお願いします",
    },
    {
      date: "2017-05-05",
      name: "御陰様でセプタゴンは4周年を迎えました。今後ともよろしくお願いします",
    },
    {
      date: "2017-01-01",
      name: "あけましておめでとうございます。2017年もセプタゴンをよろしくお願いします",
    },
    {
      date: "2016-05-05",
      name: "御陰様でセプタゴンは3周年を迎えました。今後ともよろしくお願いします",
    },
    {
      date: "2016-01-01",
      name: "あけましておめでとうございます。2016年もセプタゴンをよろしくお願いします",
    },
    {
      date: "2015-09-06",
      name: "セプタゴンの板再編計画において、HELLはルノワールに併合されました",
    },
    {
      date: "2015-05-05",
      name: "御陰様でセプタゴンは2周年を迎えました。今後ともよろしくお願いします",
    },
    { date: "2015-04-07", name: "公式Twitterアカウントを開設しました" },
    {
      date: "2015-02-22",
      name: "新型過去ログ検索をトップページに追加しました",
    },
    {
      date: "2015-01-01",
      name: "あけましておめでとうございます。2015年もセプタゴンをよろしくお願いします",
    },
    { date: "2014-10-05", name: "裏ニュー速HELL板の名前をHELLに変更しました" },
    {
      date: "2014-05-05",
      name: "御陰様でセプタゴンは1周年を迎えました。今後ともよろしくお願いします",
    },
    { date: "2014-04-20.1", name: "セプタゴンのWikiを開設しました" },
    { date: "2014-04-20.0", name: "創作全般板にタグ機能を実装しました" },
    {
      date: "2014-04-13",
      name: "SS創作板を閉鎖し、新板として創作全般板を開設しました",
    },
    {
      date: "2014-04-06",
      name: "裏VIPの避難所を移転し、セプタゴン全板の避難所も作成しました",
    },
    { date: "2014-03-04", name: "!rpg機能の使用を変更しました" },
    { date: "2014-02-26", name: "嫁リストを作成しました" },
    { date: "2014-02-22", name: "SS創作板を新板として開設しました" },
    {
      date: "2014-02-07",
      name: "当掲示板群の名称が「セプタゴン」になりました",
    },
    { date: "2014-01-16", name: "裏ニュー速HELLを新板として開設しました" },
    {
      date: "2014-01-01",
      name: "明けましておめでとうございます。今後ともよろしく申し上げます。",
    },
    {
      date: "2013-12-31",
      name: "正月限定のコマンドとして名前欄に!damaでお年玉を表示できるようにしました",
    },
    { date: "2013-12-22", name: "裏VIPのトップページを更新しました" },
    { date: "2013-12-21", name: "ニュース超速報板を追加しました" },
    { date: "2013-11-24", name: "同スレタイの乱立防止機能を導入しました" },
    { date: "2013-11-19", name: "実験用の板を追加しました" },
    { date: "2013-11-06", name: "スクリプトのアップデートを行いました" },
    { date: "2013-11-03", name: "簡単な過去ログ検索機能を実装しました" },
    {
      date: "2013-10-14",
      name: "本文中に!kakikoにてこれまでの自分自身の書き込み回数を確認できる機能を実装しました",
    },
    { date: "2013-10-06", name: "ぽんなシステムのテストが開始しました" },
    { date: "2013-10-05.1", name: "!774機能を拡張しました" },
    { date: "2013-10-05.0", name: "!rpg機能を追加、変更しました" },
    {
      date: "2013-09-29",
      name: "スレ立て時にメール欄で!774と入力することによってそのスレ内でのコテ無効化を実装しました",
    },
    {
      date: "2013-09-23",
      name: "裏VIPのトップページで本日のレス数を閲覧可能にしました",
    },
    { date: "2013-09-08", name: "ホビー全般板の試験運用を開始しました" },
    {
      date: "2013-09-07.1",
      name: "裏VIPのURLを追加したBBSMENUを作成しました(http://uravip.tonkotsu.jp/bbsmenu.pl)",
    },
    { date: "2013-09-07.0", name: "!tres,!tsureを実装しました" },
    { date: "2013-09-04", name: "裏VIPが復帰しました" },
    {
      date: "2013-08-30",
      name: "ロリポップメンテナンスのためサーバーがダウンしました",
    },
    { date: "2013-08-01", name: "掲示板一覧に「雑談ルノワール」を追加" },
    { date: "2013-05-28.1", name: "掲示板のセキュリティを向上させました" },
    { date: "2013-05-28.0", name: "掲示板スクリプトをアップデートしました" },
    { date: "2013-05-23", name: "嫁機能([名前欄]!yome)を実装しました" },
    { date: "2013-05-22", name: "!rank機能を復活させました" },
    { date: "2013-05-19", name: "鯖の安定性が向上しました" },
    {
      date: "2013-05-18",
      name: "ダイス機能（[名前欄]!D6、!D10、!D20）、RPG機能（[本文]!rpg）を実装しました",
    },
    { date: "2013-05-14", name: "!rank 機能、自動dat落ち機能を一時的に無効化" },
    {
      date: "2013-05-13",
      name: "管理人★ さんが鯖継続契約をしてくれました。感謝",
    },
    {
      date: "2013-05-08",
      name: "15時間書き込みがないと自動でdat落ちする様にしました",
    },
    { date: "2013-05-06.2", name: "運用情報・削除依頼板を開設しました" },
    { date: "2013-05-06.1", name: "裏ニュー速VIP板の1001を変更しました" },
    { date: "2013-05-06.0", name: "鯖のセキュリティを向上させました" },
  ];

  clickShowMoreButton() {
    this.setState({ isShowingMoreItems: !this.state.isShowingMoreItems });
  }
  componentWillMount() {
    this.setState({ showNewsItems: this.news });
  }

  render() {
    const newsItems: JSX.Element[] = [];
    let items: Enumerable.IEnumerable<NewsItem>;
    if (!this.state.isShowingMoreItems) {
      items = Enumerable.from(this.state.showNewsItems).take(7);
    } else {
      items = Enumerable.from(this.state.showNewsItems).take(2000);
    }
    items.forEach((x) => {
      const dotInd = x.date.indexOf(".");
      if (dotInd > 0) {
        x.date = x.date.slice(0, dotInd);
      }
      // style={{backgroundColor:"green", borderRadius:7, padding: 3, marginRight:5, fontSize:15}}
      newsItems.push(
        <li>
          <div className="container">
            <span>{x.date}</span>
          </div>
          <div className="container">{x.name}</div>
        </li>
      );
    });
    return (
      <div>
        <h2>最近の出来事</h2>
        <ul>{newsItems}</ul>
        <button onClick={this.clickShowMoreButton} className="button is-dark">
          {this.state.isShowingMoreItems ? "お知らせを畳む" : "もっと表示する"}
        </button>
      </div>
    );
  }
}
