import React from "react";

export const FutureTask = () => (
  <div>
    <h2>今後の予定</h2>
    <ul>
      <li>Zeroch Sharpの開発</li>
    </ul>
  </div>
);
