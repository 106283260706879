import React from "react";
import "../App.sass";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = ["images/hell.jpg", "images/runo.jpg", "images/uravip.jpg"];

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  onChange: (oldindex: number, newindex: number) => {
    console.log(`${oldindex} to ${newindex}`);
  },
};

const containerStyle = {
  backgroundColor: "#d7f2c3",
  marginBottom: "3.5rem",
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#d7f2c4",
  borderRadius: 7,
  padding: 15,
};

export const BoardsSlideShow = () => (
  <div className="container" style={containerStyle}>
    <Fade {...fadeProperties}>
      <div className="each-fade">
        <div className="image-container">
          <img src={fadeImages[0]} alt="裏VIP"></img>
        </div>
        <h2>裏VIP</h2>
      </div>
      <div className="each-fade">
        <div className="image-container">
          <img src={fadeImages[1]} alt="雑談ルノワール"></img>
        </div>
        <h2>雑談ルノワール</h2>
      </div>
      <div className="each-fade">
        <div className="image-container">
          <img src={fadeImages[2]} alt="HELL"></img>
        </div>
        <h2>HELL</h2>
      </div>
    </Fade>
  </div>
);
